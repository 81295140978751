import { useAppSelector } from '@/store/hooks';

import { CONTENT_BLOCKS_PHONE_TEMPLATE } from '@/shared/constants/settings';
import { getFormattedPhoneNumber } from '@/shared/utils/string';

export const FooterContacts = () => {
    const contentBlocks = useAppSelector((state) => state.contentBlocks.contentBlocks.data);
    const phone = useAppSelector((state) => state.calltrack.callTrackPhone);

    const content = contentBlocks?.contacts.content.replace(
        CONTENT_BLOCKS_PHONE_TEMPLATE,
        `<a href="tel:${getFormattedPhoneNumber(phone)}">${getFormattedPhoneNumber(phone)}</a>`,
    );

    return <div className="contentBlocks" dangerouslySetInnerHTML={{ __html: content as string }} />;
};
